import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//群标签（无分页）
export function GetSimpleTagList(data) {
    return $axios.post(`${baseURL}/Tag/GetSimpleTagList`,data);
}

//新建群标签
export function CreateTag(data) {
    return $axios.post(`${baseURL}/Tag/Create`,data);
}

//更新单个群标签
export function UpdateGroupIDsToTag(data) {
    return $axios.post(`${baseURL}/Tag/UpdateGroupIDsToTag`,data);
}

//批量设置多个群的标签
export function SetGroupTagBatch(data) {
    return $axios.post(`${baseURL}/Tag/SetGroupTagBatch`,data);
}

//获取标签列表
export function GetTagList(data) {
  return $axios.post(`${baseURL}/Tag/GetTagList`,data);
}

//编辑标签
export function UpdateTag(data) {
  return $axios.post(`${baseURL}/Tag/Update`,data);
}

//删除标签(禁用)
export function DisableTag(data) {
  return $axios.post(`${baseURL}/Tag/Disable`,data);
}

//批量打标签
export function TagBatchSetGroupTag(data) {
  return $axios.post(`${baseURL}/Tag/BatchSetGroupTag`,data);
}

//批量移除标签
export function TagBatchRemoveGroupTag(data) {
  return $axios.post(`${baseURL}/Tag/BatchRemoveGroupTag`,data);
}

//获取任务列表
export function GroupTagGetTaskList(data) {
  return $axios.post(`${baseURL}/GroupTag/GetTaskList`,data);
}

//获取任务明细
export function GroupTagGetTaskLog(data) {
  return $axios.post(`${baseURL}/GroupTag/GetTaskLog`,data);
}

//删除任务
export function GroupTagDeleteTask(data) {
  return $axios.post(`${baseURL}/GroupTag/DeleteTask`,data);
}

//新建任务
export function GroupTagCreateTask(data) {
  return $axios.post(`${baseURL}/GroupTag/CreateTask`,data);
}

//更新任务
export function GroupTagUpdateTask(data) {
  return $axios.post(`${baseURL}/GroupTag/UpdateTask`,data);
}

//获取任务详情
export function GroupTagTaskInfo(data) {
  return $axios.post(`${baseURL}/GroupTag/TaskInfo`,data);
}
