<template>
  <div class="container">
    <el-dialog title="请选择群标签" width='800px' v-model="dialogVisible">
        <div class="wrap">
            <div class="left-header">
                <el-input placeholder="请输入群标签名称" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:160px;" clearable @change="changeInputAction"></el-input>
                <el-button type="primary" @click="clearTagsAction">清除标签</el-button>
            </div>
        </div>

        <el-checkbox-group v-model="selectList" :min="0" :max="sourceList.length" class="checkbox-wrap">
            <el-checkbox v-for="(item,index) in tagList" :label="item" :key="index" class="radio-cell">{{item.TagName}}</el-checkbox>
        </el-checkbox-group>

        <div class="wrap-foot" v-if="type==0">
            <div>筛选条件:</div>
            <el-radio-group v-model="searchObj.radio" class="radio-group" @change="changeRadio">
                <el-radio :label="0">以上标签满足其中之一</el-radio>
                <el-radio :label="1">以上标签同时满足</el-radio>
                <el-radio :label="-1">无任何标签</el-radio>
            </el-radio-group>
        </div>
         <div class="wrap-foot" v-if="type==1||type==3">
            <div>新建标签:</div>
            <el-input placeholder="请输入标签名称" v-model="createStr" style="width:300px;margin-left:5px;" maxlength="10" show-word-limit></el-input>
            <el-button class="buildTag" type='primary' @click="buildTagAction">创建标签</el-button>
        </div>

        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false" >取 消</el-button>
                <el-button type="primary" @click="confirmAction" >{{type==3?'开 通':'确 定'}}</el-button>
            </div>
        </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref,unref} from "vue";
import {GetSimpleTagList,CreateTag,UpdateGroupIDsToTag,SetGroupTagBatch,TagBatchSetGroupTag,TagBatchRemoveGroupTag} from '@/helper/tag'
import {SetOpenGroups} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';

export default {
  name: "GroupFieldCop",
  setup(props, context) {
        const dialogVisible = ref(false);
        const remove=ref(false)
        //type 0:群  1:打标签覆盖 2.纯筛选 3.打标签开通群 4.批量
        const type = ref(0)
        //群id集合
        const groups = ref([])
        const createStr = ref('')
        const searchObj=reactive({
            key:"",
            radio:0,
            tagName:''
        })

        const sourceList = ref([]) //原始数据
        const tagList = ref([]) //展示数据
        const selectList = ref([]) //选中数据
        
        /*
            selectedTagArr：已选择标签的信息
            pageType：标签入口 
            radio:  初始选择  (type 0 时有效)
            groupIDs:  群id集合 (type 1 时有效)
        */  
        function initCop(pageType,selectedTagArr,radio=0,groupIDs=[],removerTag){
            dialogVisible.value = true;
            remove.value = removerTag||false
            tagList.value = [];
            sourceList.value=[];
            type.value = pageType||0;
            groups.value = groupIDs||[];
            searchObj.radio = radio||0;
            
            getTagList(selectedTagArr)
        }

        function getTagList(arr){
            selectList.value = []
            GetSimpleTagList().then(res=>{
                sourceList.value = res.list? res.list.filter(item=>item.ID>0):[]
                tagList.value = res.list? res.list.filter(item=>item.ID>0):[]
                if(arr&&arr.length>0){
                    for(let item of arr){
                        for(let item2 of res.list){
                            if(item2.ID == item.ID){
                                selectList.value.push(item2)
                                break
                            }
                        }
                    } 
                }
            })
        }

        /*************点击事件**************/
        //筛选
        function changeInputAction(){
             let arr = []
             sourceList.value.forEach(item=>{
                 if(item.TagName.toUpperCase().indexOf(searchObj.key.toUpperCase())>-1){
                     arr.push(item)
                 }
             })
            tagList.value = arr;
        }
        
        //清除条件
        function clearTagsAction(){
            selectList.value = [];
        }

        //生成标签
        function buildTagAction(){
            CreateTag({TagName:createStr.value}).then(res=>{
               getTagList(selectList.value)
            })
        }

        //切换Radio
    function changeRadio(){
      if(searchObj.radio==-1){
        selectList.value = []
      }
    }

        function confirmAction(){

            let lionArr = []
            if(searchObj.radio==0){
                lionArr = selectList.value;
            }else if(searchObj.radio==1){
                lionArr = selectList.value;
            }
            if(type.value ==1){
                let tagids = selectList.value.map(v=>v.ID)
                let param = {
                    GroupIDs:groups.value,
                    TagIDs:tagids
                }
                SetGroupTagBatch(param).then(res=>{
                    context.emit("onConfirm",searchObj.radio,lionArr)
                    dialogVisible.value = false;
                })
            } else if(type.value ==3){
                let groupsids = groups.value.map(v=>v.GroupID)
                let tagids = selectList.value.map(v=>v.ID)
                let param = {
                    GroupID:groupsids,
                    TagID:tagids
                }
                SetOpenGroups(param).then(res=>{
                    context.emit("onConfirm",searchObj.radio,lionArr)
                    dialogVisible.value = false;
                })
            }else if(type.value==4){
                if(selectList.value.length>0){
                    let tagids = selectList.value.map(v=>v.ID)
                    let param = {
                        GroupIDs:groups.value,
                        TagIDs:tagids
                    }
                    if(!remove.value){
                        TagBatchSetGroupTag(param).then(res=>{
                            context.emit("onConfirm",searchObj.radio,lionArr)
                            dialogVisible.value = false;
                        })
                    }else{
                        TagBatchRemoveGroupTag(param).then(res=>{
                            context.emit("onConfirm",searchObj.radio,lionArr)
                            dialogVisible.value = false;
                        })
                    }
                }else{
                    ElMessage({
                        type: 'error',
                        message: '请选择至少一个标签！',
                    });
                }
                
            } else {
                context.emit("onConfirm",searchObj.radio,lionArr)
                dialogVisible.value = false;
            } 
        }
        return {
            initCop,
            dialogVisible,
            confirmAction,
            searchObj,
            tagList,
            selectList,
            clearTagsAction,
            type,
            buildTagAction,
            createStr,
            groups,
            changeInputAction,
            sourceList,
            changeRadio,
            remove
        };
  },
};
</script>
<style lang='scss' scoped>
.wrap{
    width: 100%;
    max-height: 600px;
    position: relative;

    display: flex;
    flex-direction: column;
    .left-header{
        height: 30px;
        margin-bottom:10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #000;
        font-size: 14px;
    }


}

.checkbox-wrap{
    width: 100%;
    max-height:400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 20px;
    box-sizing: border-box;
    background-color: #F9F9F9;
    overflow: auto;

    .radio-cell{
        width: 30%;
        margin: 5px 0;
        font-size: 14px;
        
    }

   

}

.buildTag{
    margin-left: 20px;
}

.wrap-foot{
    margin: 10px 10px 0;
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .radio-group{
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>